import React from 'react';

const Perfil = (props) => {
    return(
        <section className="resume-section" id="about">
            <div className="resume-section-content">
                <h1 className="mb-0" style={{fontFamily: 'Montserrat', fontSize: '4rem'}}>
                    Beatriz
                    <span className="text-primary">Cordeiro</span>
                </h1>
                <div className="subheading mb-5" style={{fontFamily: 'Montserrat'}}>
                    Caranguejeira, Leiria ·{' '}
                    <a href="mailto:beatriz.cordeiro@pm.me">beatriz.cordeiro@pm.me</a>
                </div>
                <p className="lead mb-5">Atualmente a tirar a Licenciatura de Gestão de Eventos, na <a target='_blank' rel='noreferrer' href="https://www.ipleiria.pt/estm/">Escola Superior de Turismo e Tecnologia do Mar</a>. <br/>Apaixonada por música, bastante curiosa e open-minded.<br/>Tenho experiência em comunicação com públicos de diversas idades, desde crianças a pessoas mais velhas.</p>
                <div className="social-icons">
                    <a className="social-icon" target='_blank' rel='noreferrer' href="https://www.linkedin.com/in/beatriz-cordeiro281/" style={{textDecoration: 'none'}}><i className="fab fa-linkedin"></i></a>
                </div>
            </div>
        </section>
    );
}

export default Perfil;