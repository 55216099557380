import React from 'react';

const Experiencia = (props) => {
    return(
        <section className="resume-section" id="experience">
            <div className="resume-section-content">
                <h2 className="mb-5" style={{color:'#bd5d38', fontFamily: 'Montserrat'}}>Experiência</h2>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0" style={{fontFamily: 'Montserrat'}}>Rececionista {"(Estágio)"}</h3>
                        <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}>Livraria Bertrand · Leiria</div>
                        <ul>
                            <li>Atendimento ao público.</li>
                            <li>Organização geral da loja.</li>
                            <li>Tratamento de encomendas.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">fevereiro 2020 - julho 2020</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0" style={{fontFamily: 'Montserrat'}}>Rececionista e animadora {"(Estágio)"}</h3>
                        <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}>Feijão Verde Fun Park · Leiria</div>
                        <ul>
                            <li>Atendimento ao público.</li>
                            <li>Gestão de reservas.</li>
                            <li>Cuidar e animar de crianças.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">março 2018 - junho 2018</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0" style={{fontFamily: 'Montserrat'}}>Rececionista {"(Estágio)"}</h3>
                        <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}>Hotel Cinquentenário · Fátima</div>
                        <ul>
                            <li>Atendimento ao público.</li>
                            <li>Inserção da <em>rooming list</em>.</li>
                            <li>Logística dos hóspedes.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">maio 2017 - junho 2017</span></div>
                </div>
            </div>
        </section>
    )
}

export default Experiencia;