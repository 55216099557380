import React from 'react';

const Educacao = (props) => {
    return(
        <section className="resume-section" id="experience">
            <div className="resume-section-content">
                <h2 className="mb-5" style={{color:'#bd5d38', fontFamily: 'Montserrat'}}>Educação</h2>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0" style={{fontFamily: 'Montserrat'}}>Licenciatura em Gestão de Eventos</h3>
                        <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}>Escola Superior do Turismo e Tecnologia do Mar · Penice</div>
                        <p>Algumas disciplinas:</p>
                        <ul>
                            <li>Marketing de Eventos</li>
                            <li>Inglês para Eventos</li>
                            <li>Pesquisa de Mercado</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">2020 - Presente</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0" style={{fontFamily: 'Montserrat'}}>Curso Técnico Superior Profissional em Práticas Administrativas e Comunicação Empresarial</h3>
                        <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}>Escola Superior de Educação e Ciências Sociais · Leiria</div>
                        <p>Algumas disciplinas:</p>
                        <ul>
                            <li>Gestão de eventos</li>
                            <li>Práticas de Secretariado</li>
                            <li>Gestão de Empresas</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">março 2018 - junho 2018</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0" style={{fontFamily: 'Montserrat'}}>Curso Profissional de Técnico de Turismo</h3>
                        <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}>Escola Secundária Francisco Rodrigues Lobo · Leiria</div>
                        <p>Algumas disciplinas:</p>
                        <ul> 
                            <li>Operações Técnicas em Empresas Turísticas</li>
                            <li>Inserção da <em>rooming list</em>.</li>
                            <li>Logística dos hóspedes.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">maio 2017 - junho 2017</span></div>
                </div>
            </div>
        </section>
    );
}

export default Educacao;
