import React from 'react';
import Content from './Content';

const App = (props) => {
    return (
        <div id="page-top">
            <Content />
        </div>
    );
}

export default App;