import React from 'react';
import banner from './banner.jpg';

const Eventos = (props) => {
    return(
        <section className="resume-section" id="skills">
            <div className="resume-section-content">
                <h2 className="mb-5" style={{color:'#bd5d38', fontFamily: 'Montserrat'}}>Eventos</h2>
                <div className="subheading mb-3" style={{fontFamily: 'Montserrat'}}><a href='https://www.facebook.com/patasolidaria.leiria/'>Pata solidária</a></div>
                <img src={banner} class="img-fluid" alt="pata solidaria" />
                <br/><br/>
                <p>Evento realizado no âmbito da disciplina de 'Assessoria e Organização de Eventos', do Curso Técnico Superior Profissional em Práticas Administrativas e Comunicação Empresarial.</p>
                
            </div>
        </section>
    );
}

export default Eventos;