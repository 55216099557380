import React, { useState } from 'react';

const NavItem = (props) => {
    const [isMouseOver, setMouseOver] = useState(false);

    function handleMouseOver(){
        setMouseOver(true);
    }
    function handleMouseOut(){
        setMouseOver(false);
    }

    return(
        <p 
            className="nav-link js-scroll-trigger" 
            onMouseOver={handleMouseOver} 
            onMouseOut={handleMouseOut} 
            style={{color: isMouseOver ? 'black' : 'white' }}
        >
        {props.itemName}</p>
    );
}

export default NavItem;