import React from 'react';

const Footer = (props) => {
    return(
        <footer className='page-footer font-small blue pt-4 text-white'>
            <div class="footer-copyright text-center py-3">Made with ❤ by {' '}
            <a href="https://www.linkedin.com/in/jo%C3%A3o-rocha-769ab5194/" target='_blank' rel='noreferrer' className='text-white'>João Rocha</a>
            </div>
        </footer>
    );
}

export default Footer;