import React, { useState } from 'react';
import pic from './profile-pic.jpeg';
import avatar from './avatar.png';
import Perfil from './Perfil';
import Experiencia from './Experiencia';
import Educacao from './Educacao';
import Eventos from './Eventos';
import Footer from './Footer';
import NavItem from './NavItem';

const Content = (props) => {
    const [view, setView] = useState('start');

    function handlePerfilClick(){
        setView('start');
    }
    function handleExperienciaClick(){
        setView('experiencia');
    }
    function handleEducacaoClick(){
        setView('educacao');
    }
    function handleEventosClick(){
        setView('eventos');
    }

    const imgStyle = {
        cursor: 'pointer',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '5px',
        width: '220px',
        height: '370px'
    };

    const avatarStyle = {
        borderRadius: '50%',
        width: '60px',
        height: 'auto'
    };

    return(
        <div className="container-fluid p-0">
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a class="navbar-brand js-scroll-trigger" href="#">
                <span class="d-block d-lg-none"><img src={avatar} alt="avatar" style={avatarStyle} />{' '}Beatriz Cordeiro</span>
                <span class="d-none d-lg-block"><img className="img-fluid mx-auto mb-2" src={pic} alt="Profile" onClick={handlePerfilClick} style={imgStyle} id="profile-img" /></span>
            </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav">
                    <li className="nav-item" style={{cursor: 'pointer'}} id='navbar-item' onClick={handlePerfilClick}><NavItem itemName={'Perfil'} /></li>
                        <li className="nav-item" style={{cursor: 'pointer'}} id='navbar-item' onClick={handleExperienciaClick}><NavItem itemName={'Experiência'} /></li>
                        <li className="nav-item" style={{cursor: 'pointer'}} id='navbar-item' onClick={handleEducacaoClick}><NavItem itemName={'Educação'} /></li>
                        <li className="nav-item" style={{cursor: 'pointer'}} id='navbar-item' onClick={handleEventosClick}><NavItem itemName={'Eventos'} /></li>
                    </ul>
                </div>
                <Footer />
            </nav>
            {view === 'start' && <Perfil />}
            {view === 'experiencia' && <Experiencia />}
            {view === 'educacao' && <Educacao />}
            {view === 'eventos' && <Eventos />}
        </div>
    );
}

export default Content;